import React from 'react'
import CSS from './ServicesSection.module.css'

const ServicesSection = () => {
  return (
    <ServicesSectionTemplate
      heading='Work with us'
      description={
        <>
          <p>{`
            Managing teams and creating high quality work is a hair-pulling
            effort. That's why we're here: to make things effective and
            efficient.
          `}</p>
          <p>
            We constantly find ways to improve processes and how people work
            together. We care about the quality of our work.
          </p>
          <p>
            Alternatively, you can work with a mediocre team and pull your own
            hair.
          </p>
        </>
      }
      services={
        <>
          <ServiceBlock
            heading='Software development'
            subheading='hello@mashupgarage.com'
            description={
              <>
                Looking for a solid team to work on your product? Or looking to
                build a team? We are constantly looking for founders to work
                with.
              </>
            }
          />
          <ServiceBlock
            heading='Career opportunities'
            subheading='jobs@mashupgarage.com'
            description={
              <>
                We&apos;re actively seeking <strong>Software Engineers</strong>,{' '}
                <strong>Project Managers</strong>, and <strong>QA</strong>{' '}
                members to join our team and our partner companies.
                <p>
                  <strong>
                    <a href='/work-with-us/apply'>
                      You can also submit your application here.
                    </a>
                  </strong>
                </p>
              </>
            }
          />
          <ServiceBlock
            heading='Want a human to talk to about other things?'
            subheading='jason@mashupgarage.com'
            description={<>Our dear CEO is ready to talk to you.</>}
          />
        </>
      }
    />
  )
}

export interface TemplateProps {
  heading: string
  subheading?: string
  description: React.ReactNode
  services?: React.ReactNode
}

const ServicesSectionTemplate = (props: TemplateProps) => {
  const { heading, description, services } = props
  return (
    <section className={CSS.root}>
      <div className={CSS.container}>
        <div className={CSS.sectionInfo}>
          <h1 className={CSS.sectionHeading}>{heading}</h1>
          <div className={CSS.sectionDescription}>{description}</div>
        </div>
        {services ? <div className={CSS.services}>{services}</div> : null}
      </div>
    </section>
  )
}

export interface ServiceBlockProps {
  heading: string
  description: string
}

const ServiceBlock = (props: TemplateProps) => {
  const { heading, subheading, description } = props
  return (
    <div className={CSS.service}>
      <h2 className={CSS.serviceHeading}>{heading}</h2>
      <a
        href={['mailto:', subheading].join('')}
        target='_blank'
        className={CSS.serviceSubheading}
      >
        {subheading}
      </a>
      <div className={CSS.serviceDescription}>{description}</div>
    </div>
  )
}

export { ServicesSectionTemplate }
export { ServiceBlock }
export default ServicesSection
