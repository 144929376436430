import React from 'react'
import Layout from '../Layout'
import { NavArea, NavLayout } from '../Navigation'

import LargeFooterSection from '../../sections/LargeFooterSection'
import LocationSection from '../LocationSection'
import ServicesSection from './ServicesSection'

const IndexPage = () => (
  <Layout>
    <NavLayout>
      <NavArea darkAndLight>
        <ServicesSection />
      </NavArea>

      <NavArea dark>
        <LocationSection />
        <LargeFooterSection />
      </NavArea>
    </NavLayout>
  </Layout>
)

export default IndexPage
